import * as $ from "jquery";
import "./src/style.css";

// jQuery scroll
$(function () {
  const updateHeader = () => {
    const header = $(".header");
    if ($(this).scrollTop() > 0) {
      header.addClass("active");
    } else header.removeClass("active");

    $(".card").each(function (i, e) {
      if (
        $(window).scrollTop() + $(window).height() - 200 >=
        $(e).offset().top
      ) {
        $(e).addClass("card-anim");
      }
      /* if ($(e).offset().top < ($(window).scrollTop() + $(window).height())) {
          $(e).addClass('card-anim');
      } */
    });
  };

  $(window).on("scroll", () => updateHeader());
  // update header on page load
  updateHeader();

  $('a[href*="#"]').on("click", function (e) {
    if ($($(this).attr("href")).length) {
      e.preventDefault();

      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top - 100,
        },
        300,
        "swing",
      );
    }
  });

  /* Custom select dropdown */
  $(document).on("click", ".dropdown-selected", function () {
    $(this).siblings(".dropdown-list").toggleClass("active");
  });

  $(document).on("click", ".dropdown-list-item", function () {
    $(".dropdown-list-item").removeClass("selected");
    $(this).addClass("selected");

    const target = $(this).attr("data-value");
    const option = $(this).parents(".custom-select").find("option");
    option.attr("data-value", target);
    option.text(target);
    $(".dropdown-selected").text(target);
  });

  $(document).on("mouseup", function (e) {
    var container = $(".dropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $(".dropdown-list").removeClass("active");
    }
  });

  // mailChimp
  (function ($) {
    window["fnames"] = [];
    window["ftypes"] = [];
    window["fnames"][0] = "EMAIL";
    window["ftypes"][0] = "email";
    window["fnames"][1] = "FNAME";
    window["ftypes"][1] = "text";
    window["fnames"][2] = "LNAME";
    window["ftypes"][2] = "text";
    window["$mcj"] = $;
  })();
});
